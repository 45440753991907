import styled, { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
	* {
		&:target {
			border-bottom: 2px solid var(--pink) !important;
		}
	}
	body {
		background-color: #1e1e1e;
	}
	#light {
		background-color: var(--bg-d0) !important;
		.body-wrapper option {
			background-color: #fff;
			color: #111;
		}
		.menu-buttons .button, .menu-buttons button, .menu-buttons a {
			background-color: #fff
		}
		.edit-mode .article-head, .edit-mode .DraftEditor-root {
			background-color: #fff;
		}
		.article-outline {
			border-right: 1px solid rgba(0, 0, 0, 0.1);
		}
		.tag-btn {
			background: #333;
			color: #fff !important;
		}

		.btn-secondary {
			color: #000;
			&:hover {
				background: #000;
				color: #fff !important;
			}
		}
		.article-navigation {
			background-color: #f3f2f5;
			border-right: 1px solid rgba(0, 0, 0, 0.1);
		}
		.top-navigation {
			background-color: rgb(255, 255, 255, 0.9);
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			.sub-menu ul {
				background-color: #fff;
			}
		}
	}
`;
export const WikiWrapperEl = styled.div`
	.body-wrapper {
		padding: 4em;
		padding-top: 6em;
		margin: 0 auto;
		margin-bottom: 4em;
		text-rendering: optimizeLegibility;
		max-width: 53em;
		font-family: "Geologica", sans-serif;
	}
	.body-wrapper option {
		background-color: #111;
		color: #fff;
	}
	h1 {
		font-size: 2.5em;
		button {
			margin: 0 1em;
		}
	}
	h2 {
		font-size: 1.75em;
	}
	h3 {
		font-size: 1.5em;
	}
	h4 {
		font-size: 1.25em;
	}
	h6 {
		font-size: 1.1em;
	}
	h6 {
		font-size: 1em;
	}
	blockquote {
		padding: 1em;
		margin: 1em 0;
		position: relative;
		box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
		border-radius: 0.25em;
		overflow: hidden;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 0.25em;
			height: 100%;
			background: linear-gradient(to bottom, #ed238b, #8e63b0);
		}
	}
	a:not(.button) {
		color: #008cde;
		text-decoration-color: #008cde;
		text-decoration-line: underline;
		text-decoration-thickness: from-font;
		text-underline-offset: 3px;
		transition: text-underline-offset 500ms ease;
	}
	button:disabled {
		cursor: default;
	}
	a:hover,
	a:active,
	a:focus {
		text-underline-offset: 6px;
	}
	button,
	.button {
		display: inline-block;
		/* text-transform: uppercase; */
		letter-spacing: 1px;
		background: transparent;
		color: inherit;
		fill: currentColor;
		padding: 0.5em 1em;
		border-radius: 0.5em;
		border: 1px solid;
		line-height: 1;
		cursor: pointer;
		span {
			text-decoration: none !important;
		}
		* {
			vertical-align: middle;
		}
		svg {
			fill: currentColor;
		}
	}
	button:hover,
	.button:hover {
		border: 1px solid;
		svg {
			fill: #000;
		}
	}
	.btn {
		color: inherit !important;
		text-decoration: none !important;
		a {
			text-decoration: none !important;
		}
	}
	.btn-primary {
		background-color: var(--pink);
		border-color: var(--pink);
		color: #fff !important;
		color: #fff;
		a {
			color: inherit;
			text-decoration: none;
		}
	}
	.btn-secondary {
		background-color: transparent;
		color: #fff;
		&:hover {
			background: #fff;
			color: #000 !important;
		}
		a {
			color: inherit;
			text-decoration: none;
		}
	}
	.btn-muted {
		opacity: 0.8;
	}
	.menu-buttons {
		padding: 0.5em;
		display: inline-flex;
		flex-flow: wrap;
		min-height: 66px;
		a {
			line-height: 0 !important;
		}
		button,
		.button,
		a {
			background-color: var(--bg-d0);
			padding: 0.25em;
		}
		svg {
			height: 17px;
		}
	}
	mark {
		background: var(--yellow);
		font-weight: bold;
		border-radius: 0.1em;
	}
	.button-list {
		display: inline-flex;
		align-self: center;
		width: 100%;
		flex-flow: wrap;
		color: currentColor;
		fill: currentColor;
		button,
		.button,
		a {
			display: inline-block;
			min-width: 2em;
			margin: 0 0.25em 0.25em 0;
			font-size: 0.9em;
			line-height: 1.25;
			color: currentColor;
			:hover {
				color: #008cde;
			}
		}
	}
	.wiki-header {
		margin-bottom: 2em;
		h1 {
			text-align: center;
		}
		.btn-primary {
			text-transform: uppercase;
			letter-spacing: 2px;
		}
	}
	.article-list {
		margin-bottom: 2em;
		width: 100%;
	}
	.article-count {
		font-size: 0.8em;
		text-transform: uppercase;
		opacity: 0.8;
		letter-spacing: 1px;
		text-align: left;
	}
	.input-wrapper {
		padding: 1em 0;
		text-align: left;
		width: 100%;
		input {
			width: 100%;
			margin-bottom: 0.5em;
		}
		.article-categories-list {
			padding-top: 1em;
		}
	}
	.search-wrapper {
		padding: 1em 0;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		max-width: 60em;
		margin: 4em auto;
		.input-wrapper {
			display: flex;
			label,
			input {
				font-size: 1.5em;
			}
		}
		label {
			padding-right: 1em;
		}
		.sort-wrapper {
			width: 50%;
			select {
				width: 80%;
			}
		}
		.filter-wrapper {
			width: 50%;
			select {
				width: 80%;
			}
		}
		input,
		select {
			margin: 0;
			background: none;
			outline: none;
			color: currentColor;
			line-height: 1;
			border-top: none !important;
			border-right: none !important;
			border-left: none !important;
			border-bottom: 1px solid #333 !important;
		}
		input:focus,
		select:focus {
			border-bottom: 1px solid currentColor;
		}
		& > div {
		}
	}
	.categories {
		font-size: 0.75em;
	}
	.article-preview {
		padding: 1em 0;
		margin: auto;
		max-width: 48em;
		position: relative;
		.article-body {
			font-weight: 500;
			font-family: "Geologica", sans-serif;
			font-size: 0.9em;
			padding: 0.5em 0;
			line-height: 1.5;
			word-break: break-word;
		}
		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.article-categories-select {
		select {
			background: transparent;
			border-color: transparent;
			min-height: 22em;
		}
	}
	.article-categories-list {
		font-size: 0.75em;
		color: #fff;
		span,
		button {
			display: inline-block;
			margin: 0 0.25em 0.25em 0;
			padding: 0.25em 1em;
			border-radius: 0.25em;
			margin-right: 0.25em;
			border-color: transparent;
			text-transform: uppercase;
		}
	}
	.child-articles-list {
		li {
			display: block;
		}
		.btn {
			font-size: 0.8em;
			margin-bottom: 1em;
		}
		#react-select-2-listbox {
			color: #000;
		}
		.css-b62m3t-container {
			width: calc(100% - 34px);
			display: inline-block;
			margin-bottom: 1em;
		}
	}
	.article-head {
		word-wrap: break-word;

		.badge {
			font-weight: 100;
			padding: 0.25em 0.5em;
			font-size: 0.75em;
		}
		h1 > a,
		h2 > a {
			font-family: "freight-display-pro", "Georgia", serif;
			font-size: 1.5em;
			text-decoration-line: underline;
			text-decoration-thickness: from-font;
			text-decoration-color: currentColor;
			text-underline-offset: 3px;
			transition: all 500ms ease;
			color: inherit;
		}
		a:hover {
			cursor: pointer;
			text-underline-offset: 8px;
		}
		textarea {
			text-wrap: balance;
			font-family: "freight-display-pro", "Georgia", serif;
			font-size: 1.5em;
			border: none;
			background: none;
			padding: 0;
			width: 100%;
			height: auto;
			display: block;
			min-height: 0;
			resize: none;
			text-decoration-line: underline;
			text-decoration-thickness: from-font;
			text-decoration-color: currentColor;
			text-underline-offset: 3px;
			transition: all 500ms ease;
			color: currentColor;
			font-size: 2.5em;
			line-height: 1.5;
			&::-webkit-scrollbar {
				display: none;
			}
		}
		textarea:focus {
			text-underline-offset: 12px;
		}
	}
	.article-view {
		position: relative;
		margin: 0 auto;
		font-size: 1.1em;
		border-radius: 0.5em;
		&.edit-mode {
			.DraftEditor-root,
			.article-head {
				padding: 0.5em;
				background: #000;
			}
		}
		.article-outline {
			font-size: 0.8em;
			line-height: 1.5;
			a {
				color: inherit;
				text-decoration: none;
				display: inline-block;
			}
			summary {
				border-left: 2px solid transparent;
				padding: 0.5em 2.5em 0.5em 0.5em;
				position: relative;
				.btn {
					font-size: 2em;
					padding: 0 !important;
					position: absolute;
					right: 0;
					top: -0.3em;
					color: inherit;
					svg {
						transition: transform 500ms ease;
						transform: rotate(-90deg);
						transform-origin: 50% 50%;
					}
				}
			}
			details[open] > summary {
				border-left: 2px solid var(--violet);
				.btn svg {
					transform: rotate(0deg);
				}
			}
			ul {
				padding: 1em;
			}
			li {
				font-size: 0.8em;
				padding-bottom: 0.5em;
				a {
					border-left: 2px solid transparent;
					padding-left: 0.5em;
				}
				a.active {
					border-left: 2px solid var(--violet);
					font-weight: bold;
				}
				&.header-two {
					padding-left: 1em;
				}
				&.header-three {
					padding-left: 2em;
				}
				&.header-four {
					padding-left: 3em;
				}
				&.header-five {
					padding-left: 4em;
				}
				&.header-six {
					padding-left: 5em;
				}
			}
		}
		.article-categories {
			padding: 0;
			form {
				margin-top: 2em;
			}
			.close-button {
				padding: 0;
				width: 1em;
				height: 1em;
				text-align: center;
				line-height: 0;
				font-size: 1.5em;
			}
		}
		.edit-button {
			float: right;
		}
		.email-button {
			float: right;
		}
		.article-status {
			padding: 1em 0;
			select {
				letter-spacing: 1px;
				outline: none;
				background: none;
				border: none;
				padding: 0 1em;
				padding-bottom: 0;
				border-bottom: 2px solid transparent;
			}
			select:focus {
				border-bottom: 2px solid currentColor;
			}
		}
		.article-body {
			font-family: "Geologica", sans-serif;
			line-height: 1.5;
			font-weight: 300;
			padding: 1em 0;
			margin: auto;
			word-break: break-word;
			& > div:nth-child(1) > div:nth-child(1) {
				position: sticky;
				top: 0;
				z-index: 4;
			}
			iframe {
				width: 560px;
				height: 315px;
				margin: auto;
				display: block;
			}
			img {
				margin: auto;
				display: block;
			}
			footer {
				margin-top: 4em;
				padding-top: 4em;
				border-top: 1px solid;
			}
			ol,
			ul {
				line-height: 1.25;
				font-size: 0.9em;
			}
		}
		/**HACK: weird fix for button */
		.DraftEditor-root + div button {
			padding: 0;
		}
		.article-aside {
			font-size: 0.6em;
			letter-spacing: 1px;
			padding: 1em 0;
			.article-information {
				padding: 2em 0;
			}
			input {
				width: calc(100% - 2.5em);
				background: none;
				border: none;
				padding: 0.5em 0;
				margin-bottom: 0.5em;
				border-bottom: 2px solid #333;
			}
			input:focus,
			textarea:focus {
				text-underline-offset: 9px;
			}
		}
	}
	.article-categories {
		padding-right: 0.25em;
		input {
			background: none;
			border: none;
			font-size: 1.5em;
			padding: 1em 0;
			padding-bottom: 0;
			border-bottom: 2px solid transparent;
		}
		input:focus {
			border-bottom: 2px solid currentColor;
		}
		.add-button {
			padding: 0.25em;
			font-size: 2em;
			min-width: 1.5em;
			margin: 0;
			text-align: center;
			vertical-align: middle;
		}
		.close-button {
			padding: 0;
			margin: 0;
			text-align: center;
			font-size: 1em;
			vertical-align: middle;
		}
	}
	.reader-mode .RichEditor-controls {
		display: none;
	}
	.inline-toolbar > div {
		z-index: 10;
	}
	.article-history {
		margin-top: 1em;
		p {
			font-size: 0.8em;
			font-weight: 400;
		}
		ul {
			list-style: circle;
			font-size: 0.8em;
			padding-left: 1em;
			margin-top: 1em;
			li {
				margin-top: 0.5em;
				button {
					font-size: 0.8em;
				}
			}
		}
	}
	.top-navigation {
		background-color: rgba(30, 30, 30, 0.85);
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		backdrop-filter: blur(3px);
		position: fixed;
		top: 0;
		transition: top 500ms ease;
		z-index: 99;
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: start;
		width: 100%;
		& > button {
			flex: 1 1;
			width: 100%;
		}
		.logo-button {
			padding: 1em;
		}
		.wiki-button {
			padding: 1em;
			.wiki-icon {
				padding: 0.5em;
				margin-right: 0.5em;
				background-image: var(--icon-wiki-lg);
				background-repeat: no-repeat;
				background-position: center;
			}
		}
		.icon-user {
			align-self: center;
			margin: 0.5em 1em 0 auto;
			border: 2px solid;
			button {
				padding: 0 !important;
			}
			img {
				width: 30px;
				height: 30px;
			}
		}
		.profile-button {
			background-repeat: no-repeat;
			background-size: cover;
			border: 2px solid;
			height: 3em;
			padding: 1em;
			flex: 0 0 2em;
			align-self: center;
			width: 3em !important;
			border-radius: 10em !important;
			margin: 1em;
			position: relative;
		}
		.theme-button {
			background-image: var(--icon-moon-g);
			background-repeat: no-repeat;
			border: none;
			padding: 1em 1em 1em 3em;
		}
		.menu-item {
			z-index: 9999999;
			align-content: center;
			.btn {
				flex: 1 1;
				width: 100%;
				text-transform: uppercase;
				border-radius: 0;
			}
			.btn-logo a {
				opacity: 1;
				display: inline-block;
				height: 54px;
				padding: 0;
			}
			a,
			.btn {
				text-decoration: none;
				color: inherit;
				opacity: 0.75;
				letter-spacing: 1px;
				word-break: break-word;
				&:hover {
					opacity: 1;
				}
			}
			.active {
				opacity: 1;
				border-bottom: 2px solid var(--violet);
			}

			&:last-child .sub-menu {
				right: 0;
			}
		}
		.sub-menu {
			min-width: 10em;
			max-width: 30em;
			font-size: 0.8em;
			position: absolute;
			left: -9999999em;
			z-index: 100;
			opacity: 0;
			transition: opacity 500ms ease;
			.icon {
				width: 1em;
				height: 1em;
				display: inline-block;
				margin-right: 0.5em;
			}
			ul {
				padding: 1em;
				box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
				border-radius: 1em;
				border: 1px solid rgba(0, 0, 0, 0.1);
				margin-top: 1em !important;
				background: rgba(25, 25, 25, 0.95);
				backdrop-filter: blur(3px);
			}
			a {
				display: block;
			}
			.btn {
				list-style-type: none;
				margin: 0;
				text-align: left;
				border-color: transparent;
			}
			li:not(:last-child) {
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			}
		}
		.menu-item:hover .sub-menu {
			left: auto;
			opacity: 1;
		}
		.sub-menu:hover {
			display: block;
			opacity: 1;
		}
		.sub-sub-menu {
			padding: 1em;
		}
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			li {
				padding: 0.1em 0;
			}
		}
	}

	.article-navigation {
		font-weight: 500;
		a {
			text-decoration: none;
		}
		li {
			list-style-type: none !important;
		}
	}
	.tag-menu {
		text-align: left;
	}
	.btn-tertiary {
		letter-spacing: 2px;
		border-color: transparent;
		background: #000;
		color: #fff;
		text-decoration: none !important;
		a {
			color: inherit;
			text-decoration: none;
		}
	}
	.tag-btn {
		letter-spacing: 2px;
		border-color: transparent;
		background: #fff;
		color: #000 !important;
		text-decoration: none !important;
		a {
			color: inherit;
			text-decoration: none;
		}
	}
	.btn-logo {
		font-family: "freight-display-pro", "Georgia", serif;
		border-color: transparent !important;
		opacity: 1 !important;
		height: 100%;
		padding: 0;
		img {
			height: 100%;
			padding: 8px;
		}
	}

	.style_guide {
		padding: 2em;
		border: 1px solid;
		section {
			padding: 1em;
		}
		section > div {
			padding: 1em;
		}
	}
	.article-navigation {
		background-color: rgb(0, 0, 0, 0.9);
		display: block;
		position: fixed;
		padding-top: 1em;
		left: 0;
		top: 0px;
		min-height: calc(100% - 60px);
		width: 20em;
		max-height: calc(100vh - 5em);
		overflow-y: auto;
		z-index: 9;
		&.article-navigation-open {
		}
		&.article-navigation.article-navigation-closed {
			overflow: hidden;
		}
		&.article-navigation-closed *:not(:first-child) {
			opacity: 0;
			pointer-events: none;
		}
		.menu-header {
			border-bottom: 1px solid rgba(30, 30, 30, 0.35);
			a {
				color: inherit;
				text-transform: uppercase;
    			font-weight: 400;
			}
		}
		.menu-item button {
			margin: 0;
		}
		.menu-item a {
			display: block;
			border-bottom: 1px solid rgba(30, 30, 30, 0.35);
			text-align: left;
			color: inherit;
			padding: 1em;
		}
		.header-one {
			a {
				border: none;
				padding: 0.5em;
			}
		}
		.header-two {
			color: rgba(125, 125, 125, 0.95);
			a {
				border: none;
				padding: 0.5em;
			}
		}
	}
	.hide-mobile {
		display: none;
	}
	@media screen and (min-width: 60em) {
		.hide-mobile {
			display: block;
		}
	}
	@media screen and (min-width: 80em) {
		.hide-mobile {
			display: block;
		}
		.article-outline {
			/* display: block;
			position: fixed;
			left: 0;
			top: 67px;
			min-height: calc(100% - 67px);
			width: 20em;
			max-height: calc(100vh - 5em);
			overflow-y: auto; */
		}
		.body-wrapper {
			width: calc(100% - 40em);
		}
		.article-aside {
			position: fixed;
			top: 60px;
			right: 1em;
			width: 20em;
			max-height: calc(100vh - 5em);
			overflow-y: auto;
		}
		.tag-menu {
			position: fixed;
			right: 0;
			top: 67px;
			width: 20em;
			max-height: calc(100vh - 5em);
			overflow-y: auto;
			font-size: 0.8em;
			button {
				display: block;
				margin-bottom: 0.1em;
				border-color: transparent;
				text-align: left;
			}
			h5 {
				padding: 1em 0 0.25em 0;
			}
		}

		.article-navigation {
			position: fixed;
			left: 0;
			top: 5em;
			width: 20em;
			max-height: calc(100vh - 5em);
			overflow-y: auto;
			font-size: 0.8em;
		}
	}
`;
